import Moment from 'moment';
import React, { Component } from 'react';
import { TooltipMenu, translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import swal from 'sweetalert2';

import DateTime from '_class/DateTime';
import User from '_class/User';
import { getSection, setActiveSection } from 'actions/sections';
import { getTaskDetails, setActiveAssignment, getMicrosoftAssignmentDetails } from 'actions/assignments';
import {
	getScheduleItem,
	deleteCalendarEvent,
	updateCalendarEvent,
	clearScheduleItem,
} from 'actions/schedule';
import { submitAttendence, updateAttendance, clearForecast } from 'actions/absence';
import { setPageTitle, setPageActions } from 'actions/header';
import { getParameterByName } from 'helpers/url';

import Modal from 'containers/Modals/Modal';
import CalendarEvent from 'containers/Forms/CalendarEvent';

import PostContent from 'components/Presentation/PostContent';
import UserAbsence from 'components/List/UserAbsence';

import { Block, Button, Icon, getActiveLanguage } from '@haldor/ui';
import { Expandable, Spinner } from 'UI';

import AssignmentForm from 'containers/Forms/AssignmentForm/AssignmentForm';
import MultipleAssignmentForm from 'containers/Forms/AssignmentForm/MultipleAssignmentForm';
import DisplayAssignment from 'components/BlockEditor/Blocks/AssignmentBlock/DisplayAssignment';
import AssignmentSelect from 'containers/Forms/Partials/AssignmentSelect';

import './_Lesson.scss';

class SingleLesson extends Component {
	constructor(props) {
		super(props);

		let descriptionOpen = true;

		if (localStorage.getItem('haldor-lesson-description') != null) {
			descriptionOpen = JSON.parse(localStorage.getItem('haldor-lesson-description'));
		}

		const locale = getActiveLanguage();
		Moment.locale(locale);

		this.state = {
			descriptionOpen,
			loadingSchedule: false,
			saving: false,
			editForm: false,
			createEvent: false,
			formName: '',
			lang: locale,
			loadingAssignments: false,
			selectedAssignments: [],
			assigmentOpen: true,
			hasMadeChange: false,
		};

		this._isMounted = true;
	}

	componentDidMount() {
		this.setState({ loadingSchedule: true });

		this.props.getScheduleItem(this.props.match.params.id).then(async () => {

			// Redirect to page for CalendarEvents if schedule is a CalendarEvent
			if (this.props?.schedule?.type === 'CALENDAR_EVENT') {
				this.props.history.push('/calendarEvent/' + this.props.match.params.id);
			}

			this.props.setPageTitle(this.props?.schedule?.title);

			if (this.props?.schedule?.section != null && this.props.schedule?.section.id != 0) {
				if (this.props.schedule.section.parentId != null) {
					await this.props.getSection(this.props?.schedule?.section.parentId, true);
				} else {
					await this.props.getSection(this.props?.schedule?.section.id, true);
				}
			}

			if (this.props?.schedule?.relationships?.length > 0) {
				this.setState({ loadingAssignments: true });
				let promises = this.props?.schedule?.relationships.map((items) => {
					let containsLetters = /[a-zA-Z]/.test(items.referenceId);

					if (containsLetters) {
						return new Promise((resolve) => {
							this.props.getMicrosoftAssignmentDetails(items.referenceId).then(() => {
								if (this.props.details != null) {
									this.props.details.section = this.props.group;
									this.setState((prevState) => {
										return {
											selectedAssignments: [
												...prevState.selectedAssignments,
												this.props.details,
											],
										};
									});

									resolve(1);
								}
							});
						});
					} else {
						return new Promise((resolve) => {
							this.props.getTaskDetails(items.referenceId).then(() => {
								if (this.props.assignment) {
									this.setState((prevState) => {
										return {
											selectedAssignments: [
												...prevState.selectedAssignments,
												this.props.assignment,
											],
										};
									});

									resolve(1);
								}
								this.props.setActiveAssignment(); // clear active assignemnet set in redux by getTaskDetails above
							});
						});
					}
				});

				Promise.all(promises).then(() => {
					this.setState({ loadingAssignments: false });
				});
			}

			this.setState({ loadingSchedule: false });
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevState.hasMadeChange !== this.state.hasMadeChange &&
			prevState.selectedAssignments !== this.state.selectedAssignments
		) {
			//save searched assigment to lesson
			this.setState({ loadingSchedule: true });
			let relation = [];
			this.state.selectedAssignments.map((items) => {
				let obj = {
					CalendarEventId: 0,
					ReferenceType: 'ASSIGNMENT',
					ReferenceId: items?.externalID ?? items.id,
				};
				relation.push(obj);
			});
			let changed = this.props.schedule;
			changed.relationships = relation;

			this.props.updateCalendarEvent(changed)
				.then(() => {
					this.props.getScheduleItem(this.props.match.params.id);
				})
				.catch(() => { });
			this.setState({ loadingSchedule: false, hasMadeChange: false });
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.props.clearScheduleItem();
		this.props.clearForecast();
		this.props.setActiveSection();
	}

	//check how many of an assignment is locked for the students
	getLockedStatus = (item) => {
		let total = item?.totalTasks;
		let open = item?.lockedWorkspaces === 0 ? item?.totalTasks : item?.lockedWorkspaces;
		return { open: open, total: total };
	};

	redirect = () => {
		if (getParameterByName('redirect') != null) {
			if (getParameterByName('redirect') == 'list') {
				this.props.history.push('/report/attendance');
			}

			if (getParameterByName('redirect') == 'back') {
				this.props.history.goBack();
			}
		} else {
			this.props.history.push('/schedule');
		}
	};

	onAbsenceSubmit = (values) => {
		const { schedule } = this.props;

		return new Promise(async (resolve) => {
			let invalidAbsence = values.find((absence) => {
				if (absence.type == 'INVALID_LATE' || absence.type == 'VALID_LATE') {
					return (
						absence.lateDuration == null ||
						absence.lateDuration == 0 ||
						isNaN(absence.lateDuration)
					);
				}

				return false;
			});

			if (invalidAbsence != null) {
				const prompt = await swal.fire({
					title: this.props.translate('report-absence'),
					text: this.props.translate('you-have-absence-set-to-zero'),
					showCancelButton: true,
					showConfirmButton: false,
					cancelButtonText: this.props.translate('okey'),
				});

				if (prompt.value == null) {
					resolve(0);
					return false;
				}
			}

			if (schedule.attendanceReported != null) {
				this.props.updateAttendance(values, this.props.match.params.id).then(() => {
					resolve(1);

					this.redirect();
				});
			} else {
				this.props.submitAttendence(values, this.props.match.params.id).then(() => {
					schedule.attendanceReported = new DateTime().getTimeStamp();
					resolve(1);

					this.redirect();
				});
			}
		});
	};

	searchAssigment = (value) => {
		if (this.props.group != null) {
			value.section = this.props.group;
		}

		let items = value?.reference ?? value;
		this.setState((prevState) => {
			return {
				selectedAssignments: items
					? prevState.selectedAssignments.filter((x) => x.id !== items.id).concat(items)
					: prevState.selectedAssignments,
				createEvent: false,
				hasMadeChange: true,
			};
		});
	};

	searchForm = () => {
		this.setState({ createEvent: !this.state.createEvent, formName: 'Search assignment' });
	};

	toggleAssignment = () => {
		this.setState({ createEvent: !this.state.createEvent, formName: 'Create assignment' });
	};
	toggleGroupAssignment = () => {
		this.setState({ createEvent: !this.state.createEvent, formName: 'Create group assignment' });
	};

	closeModal = (skip = true) => {
		if (skip == true) {
			this.setState({ createEvent: !this.state.createEvent });
		} else {
			swal.fire({
				title: this.props.translate('are-you-sure'),
				text: this.props.translate('close-form-prompt'),
				showCancelButton: true,
				cancelButtonText: this.props.translate('No'),
				confirmButtonText: this.props.translate('Yes'),
			}).then((result) => {
				if (result.value != null) {
					this.setState({ createEvent: !this.state.createEvent });
				}
			});
		}
	};

	closeModalAssignment = (value) => {
		this.setState((prevState) => {
			{
				return {
					selectedAssignments: this.props.createdGroupAssignment
						? [...prevState.selectedAssignments, this.props.createdGroupAssignment]
						: prevState.selectedAssignments,
					createEvent: false,
					hasMadeChange: this.props.createdGroupAssignment ? true : false,
				};
			}
		});
	};

	closeGroupModal = () => {
		this.setState((prevState) => {
			{
				return {
					selectedAssignments: this.props.createdAssignments
						? [...prevState.selectedAssignments, this.props.createdAssignments[0]]
						: prevState.selectedAssignments,
					createEvent: false,
					hasMadeChange: this.props.createdAssignments ? true : false,
				};
			}
		});
	};

	toggleEdit = (reload = false) => {
		this.setState({ editForm: !this.state.editForm });

		if (reload) {
			this.props.getScheduleItem(this.props.match.params.id).then(() => {
				this.props.setPageTitle(this.props.schedule?.title);

				if (reload?.relationships) {
					this.setState({ loadingAssignments: true, selectedAssignments: [] });

					const promises = this.props?.schedule?.relationships.map((items) => {
						let containsLetters = /[a-zA-Z]/.test(items.referenceId);
						if (containsLetters) {
							return new Promise((resolve) => {
								this.props.getMicrosoftAssignmentDetails(items.referenceId).then(() => {
									if (this.props.details != null) {
										this.props.details.section = this.props.group;
										this.setState((prevState) => {
											{
												return {
													selectedAssignments: [
														...prevState.selectedAssignments,
														this.props.details,
													],
												};
											}
										});

										resolve(1);
									}
								});
							});
						} else {
							return new Promise((resolve) => {
								this.props.getTaskDetails(items.referenceId).then(() => {
									this.setState({
										selectedAssignments: [
											...this.state.selectedAssignments,
											this.props.assignment,
										],
									});
									resolve(1);
								});
							});
						}
					});

					Promise.all(promises).then(() => {
						this.setState({ loadingAssignments: false });
					});
				}
			});
		}
	};

	onDescriptionTogle = (open) => {
		this.setState({ descriptionOpen: open });
		localStorage.setItem('haldor-lesson-description', JSON.stringify(open));
	};

	onAssigmentsToggle = (open) => {
		this.setState({ assigmentOpen: open });
	};

	removeAssigment = (id) => {
		this.setState((prevState) => {
			{
				return {
					selectedAssignments: prevState.selectedAssignments.filter((x) => x.id !== id.id),
					hasMadeChange: true,
				};
			}
		});
	};

	onRemove = () => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('are-you-sure-you-want-to-delete'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('No'),
			confirmButtonText: this.props.translate('Yes'),
		}).then((result) => {
			if (result.value != null) {
				this.props.deleteCalendarEvent(this.props.match.params.id).then(() => {
					this.redirect();
				});
			}
		});
	};

	onCreateTeamsMeeting = () => {
		// Create Teamsmeeting and reload page
		let values = {
			id: this.props.schedule.id,
			addTeamsMeeting: true,
		};

		this.setState({ saving: true });
		this.props.updateCalendarEvent(values).then(() => {
			this.props.getScheduleItem(this.props.match.params.id).then(() => {
				this.setState({ saving: false });
			});
		});
	};

	onJoinTeamsMeeting = () => {
		window.open(this.props.schedule.teamsMeeting);
	};

	onOptionsTeamsMeeting = () => {
		window.open(this.props.schedule.teamsMeetingData.optionsUrl);
	};

	isLoading = () => {
		return (
			<div className='container single-task'>
				<Spinner center />
			</div>
		);
	};

	noDataFound = () => {
		return (
			<div className='container single-task'>
				<h1>{this.props.translate('no-results')}</h1>
			</div>
		);
	};

	renderTeamsHtml = () => {
		var strHtml = this.props.schedule.teamsMeetingData.joinHtml;
		const locale = getActiveLanguage();

		strHtml = strHtml.replace('Learn more about Teams', '');

		if (locale == 'sv-se') {
			strHtml = strHtml.replace(
				'Join Microsoft Teams Meeting',
				'Anslut till Microsoft Teams-möte'
			);
			strHtml = strHtml.replace('Meeting options', 'Mötesalternativ');
			strHtml = strHtml.replace('Learn more about Teams', 'Läs mer om Teams');
			strHtml = strHtml.replace('en-US', 'sv-SE');
		}

		strHtml = strHtml.replace('width:100%;height: 20px;', 'width:100%;height: 1px;');
		strHtml = strHtml.replace('margin-top: 24px;', 'margin-top: 5px;');
		strHtml = strHtml.replace('white-space:nowrap;color:gray;opacity:.36;', '');
		strHtml = strHtml.replace('white-space:nowrap;color:gray;opacity:.36;', '');
		strHtml = strHtml.replace('|', '');
		strHtml = strHtml.replace(
			'________________________________________________________________________________',
			'<hr/>'
		);
		strHtml = strHtml.replace(
			'________________________________________________________________________________',
			'<hr/>'
		);

		return <div dangerouslySetInnerHTML={{ __html: strHtml }} />;
	};

	renderTeamsHtmlFromData = () => {
		const user = new User(this.props.currentUser);

		return (
			<div className='action-section'>
				<a onClick={this.onJoinTeamsMeeting}>{this.props.translate('join-teamsmeeting')}</a>

				{(user.isTeacher() || user.isAdministrator()) && this.props.schedule.teamsMeetingData != null ? (
					<a onClick={this.onOptionsTeamsMeeting}>
						{this.props.translate('options-teamsmeeting')}
					</a>
				) : null}
			</div>
		);
	};

	renderTeamsSettings = () => {
		const { schedule } = this.props;
		const user = new User(this.props.currentUser);

		return (
			<div>
				{this.props.services.teamsMeeting ? (
					<div>
						{schedule.teamsMeeting != null ? (
							<div style={{ marginTop: '1rem' }}>
								{this.props.schedule.teamsMeetingData.joinHtml != null
									? this.renderTeamsHtml()
									: this.renderTeamsHtmlFromData()}
							</div>
						) : user.isTeacher() || user.isAdministrator() ? (
							<div className='action-section'>
								<Button
									onClick={this.onCreateTeamsMeeting}
									style={{ marginTop: 5 }}
									disabled={this.state.saving}
									type='secondary'
								>
									{this.state.saving ? <Spinner center /> : null}
									{this.props.translate('create-teamsmeeting')}
								</Button>
							</div>
						) : null}
					</div>
				) : null}
			</div>
		);
	};

	render() {
		if (this.state.loadingSchedule) {
			return this.isLoading();
		} else {
			if (this.props.schedule == null) {
				return this.noDataFound();
			}
		}

		let formTitle = this.props.translate(this.state.formName);
		const { schedule, translate } = this.props;
		const user = new User(this.props.currentUser);
		const disableDelete = schedule.attendanceReported != null;

		if (this.state.formName !== 'Search assignment' && schedule.section != null) {
			formTitle = `${translate(this.state.formName)} ${translate('for')} ${this.props?.group?.title}`
		}

		return (
			<div className='single-task lesson'>
				<Modal
					isOpen={this.state.editForm}
					onClose={this.toggleEdit}
					title={this.props.translate('Edit')}
				>
					<CalendarEvent
						hasDate={Moment.utc(this.props.schedule.startTime).local()}
						onSubmit={this.toggleEdit}
						onAbort={this.toggleEdit}
					/>
				</Modal>

				<div className='single-section form left-side' style={{ paddingTop: 0 }}>
					<Block>
						<Expandable
							open={this.state.descriptionOpen}
							onChange={this.onDescriptionTogle}
							title={this.props.translate('Description')}
						>
							{schedule.description != null ? (
								<div style={{ marginTop: '1rem' }}>
									<PostContent>{schedule.description}</PostContent>
								</div>
							) : null}

							<div style={{ marginTop: '1rem' }}>
								{schedule.section != null && schedule.section?.title != null ? (
									<div className='card-meta' style={{ marginRight: '.55rem' }}>
										<Link
											style={{
												margin: 0,
												textDecoration: 'none',
												color: '#363636',
											}}
											to={`/groups/${schedule.section.id}`}
										>
											{schedule.section.title}
										</Link>
									</div>
								) : null}

								{schedule.attendanceReported != null ? (
									<div className='card-meta'>
										{this.props.translate('reported')}
									</div>
								) : null}

								<div className='clearfix'></div>
							</div>

							<div style={{ marginTop: '1rem' }}>
								<span className='title'>{translate('time')}</span>
								{new DateTime(schedule.startTime).getLongDateWithTime().capitalize()}
							</div>

							{schedule.room != null && schedule.room != '' ? (
								<div style={{ marginTop: '1rem' }}>
									<span className='title'>{translate('room')}</span>
									{schedule.room}
								</div>
							) : null}

							{schedule.scheduleSubject.primaryDay != '' ? (
								<div style={{ marginTop: '1rem' }}>
									<span className='title'>{translate('week-day')}</span>
									{new DateTime(schedule.startTime).getDay().capitalize()}
								</div>
							) : null}

							{schedule.length != '' ? (
								<div style={{ marginTop: '1rem' }}>
									<span className='title'>{translate('length')}</span>
									{schedule.length +
										' ' +
										translate('minutes').toLowerCase()}
								</div>
							) : null}
						</Expandable>
					</Block>

					{this.state.selectedAssignments.length > 0 || this.state.loadingAssignments ? (
						<Block>
							<Expandable
								open={this.state.assigmentOpen}
								onChange={this.onAssigmentsToggle}
								title={this.props.translate('Assignments')}
							>
								<div
									className='form-row editor-block active'
									style={{ marginTop: '2rem' }}
								>
									<div className='block--assignment-block'>
										{this.state.loadingAssignments && (
											<div className='container single-task'>
												<Spinner center />
											</div>
										)}

										{this.state.selectedAssignments.map((items) => (
											<div className='assignment-resource single' key={items.id} style={{ padding: '1.65rem' }}>
												<DisplayAssignment
													useLink={true}
													displayLocked={true}
													lockedItems={this.getLockedStatus(items)}
													remove={() => this.removeAssigment(items)}
													assignment={items}
													permission={!user.isStudent() ? true : false}
													customPermission={true}
												/>
											</div>
										))}
									</div>
								</div>
							</Expandable>
						</Block>
					) : null}

					{this.props.services.attendance && (user.isTeacher() || user.isAdministrator()) ? (
						<div>
							<div style={{ marginBottom: '2rem' }} />

							<Block>
								<span className='title'>{this.props.translate('report-absence')}</span>

								<div style={{ margin: '0 -1.7rem', marginTop: '1.5rem' }}>
									<UserAbsence onSubmit={this.onAbsenceSubmit} />
								</div>
							</Block>
						</div>
					) : null}
				</div>

				<div className='single-actions right-side' style={{ paddingTop: 0 }}>
					{(user.isTeacher() || user.isAdministrator() || user.isPedagogue() || user.isSchoolLeader() || user.isStudent()) ? (
						<div className='action-section'>
							<h3>{this.props.translate('tools')}</h3>

							{user.isTeacher() || user.isAdministrator() ? (
								<Button onClick={this.toggleEdit} type='secondary'>
									<Icon name='Pen_Small' /> {this.props.translate('Edit')}
								</Button>
							) : null}

							{user.isTeacher() || user.isAdministrator() ? (
								<div>
									<Button
										onClick={this.onRemove}
										type='secondary'
										disabled={disableDelete}
										style={{ marginTop: 5 }}
									>
										{this.props.translate('Delete')}
									</Button>
								</div>
							) : null}

							{this.props.services.teamsMeeting ?
								this.renderTeamsSettings()
								: null}

							{user.isTeacher() && this.props.services.advancedSchedule || user.isAdministrator() && this.props.services.advancedSchedule ? (
								<TooltipMenu
									trigger={
										<Button type='secondary' style={{ marginTop: 5 }}>
											<Icon name='Plus' /> {this.props.translate('Add assignment')}
										</Button>
									}
								>
									<TooltipMenu.Item onClick={this.searchForm}>
										{this.props.translate('Search assignment')}
									</TooltipMenu.Item>

									<TooltipMenu.Item onClick={this.toggleAssignment}>
										{this.props.translate('Create assignment')}
									</TooltipMenu.Item>

									<TooltipMenu.Item onClick={this.toggleGroupAssignment}>
										{this.props.translate('Create group assignment')}
									</TooltipMenu.Item>
								</TooltipMenu>
							) : null}
						</div>
					) : null}
				</div>

				<div style={{ clear: 'both' }} />

				<Modal
					isOpen={this.state.createEvent}
					onClose={this.closeModal}
					title={formTitle}
				>
					{this.state.formName.includes('Search') ? (
						<AssignmentSelect
							filterResults={this.props.filterResults}
							onSelect={this.searchAssigment}
							includeAssignmentsWithPlans={false}
							section={this.props.group}
						/>
					) : this.state.formName.includes('group') ? (
						<AssignmentForm
							onAbort={this.closeModalAssignment}
							editView={false}
							cloneView={false}
							lesson={this.props.schedule}
							dontRedirect={true}
							groupid={this.props.group?.id}
						/>
					) : (
						<MultipleAssignmentForm
							onAbort={this.closeGroupModal}
							editView={false}
							cloneView={false}
							lesson={this.props.schedule}
							section={this.props.group}
							groupid={this.props.group?.id}
						/>
					)}
				</Modal>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		services: state.Services.availableServices,
		user: state.user.currentUser,
		translate: translate(state.Languages.translations),
		schedule: state.schedule.activeScheduleItem,
		currentUser: state.user.currentUser,
		languages: state.Languages.languages,
		group: state.sections.activeSection,
		createdAssignments: state.assignments.newAssignments,
		createdGroupAssignment: state.assignments.newAssignment,
		assignment: state.assignments.active_assignment,
		details: state.assignments.teamsAssignmentDetails,
		attendance: state.Absence.calendarAttendence,
		forecast: state.Absence.forecast,
	};
}

export default withRouter(
	connect(mapStateToProps, {
		setActiveAssignment,
		getScheduleItem,
		submitAttendence,
		updateAttendance,
		deleteCalendarEvent,
		setPageActions,
		setPageTitle,
		updateCalendarEvent,
		clearScheduleItem,
		clearForecast,
		getSection,
		setActiveSection,
		getMicrosoftAssignmentDetails,
		getTaskDetails,
	})(SingleLesson)
);
